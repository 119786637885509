<script setup lang="ts"></script>

<template>
    <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M2.18701 5.27344L3.12108 16.5493C3.18802 17.3624 3.88066 18 4.69692 18H13.3033C14.1196 18 14.8123 17.3624 14.8792 16.5493L15.8132 5.27344H2.18701ZM6.36291 15.8906C6.08686 15.8906 5.85462 15.6759 5.83711 15.3962L5.30977 6.88841C5.29173 6.59746 5.51265 6.34718 5.80311 6.32914C6.10437 6.30802 6.34385 6.53154 6.36238 6.82249L6.88972 15.3303C6.90839 15.6316 6.67 15.8906 6.36291 15.8906ZM9.52746 15.3633C9.52746 15.6548 9.2916 15.8906 9.00012 15.8906C8.70864 15.8906 8.47277 15.6548 8.47277 15.3633V6.85547C8.47277 6.56399 8.70864 6.32812 9.00012 6.32812C9.2916 6.32812 9.52746 6.56399 9.52746 6.85547V15.3633ZM12.6905 6.88845L12.1631 15.3963C12.1458 15.6731 11.9151 15.9038 11.6039 15.8896C11.3134 15.8716 11.0925 15.6213 11.1105 15.3303L11.6379 6.82253C11.6559 6.53157 11.9108 6.3199 12.1971 6.32918C12.4876 6.34721 12.7085 6.59749 12.6905 6.88845Z"
            fill="currentColor"
        />
        <path
            d="M15.8555 2.10938H12.6914V1.58203C12.6914 0.709664 11.9817 0 11.1094 0H6.89062C6.01826 0 5.30859 0.709664 5.30859 1.58203V2.10938H2.14453C1.56203 2.10938 1.08984 2.58156 1.08984 3.16406C1.08984 3.7465 1.56203 4.21875 2.14453 4.21875C6.9949 4.21875 11.0053 4.21875 15.8555 4.21875C16.438 4.21875 16.9102 3.7465 16.9102 3.16406C16.9102 2.58156 16.438 2.10938 15.8555 2.10938ZM11.6367 2.10938H6.36328V1.58203C6.36328 1.29108 6.59967 1.05469 6.89062 1.05469H11.1094C11.4003 1.05469 11.6367 1.29108 11.6367 1.58203V2.10938Z"
            fill="currentColor"
        />
    </svg>
</template>
